<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
export default {
  name: "EventSalesList",
  page: {
    title: "Event Sales",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {Multiselect, Layout, PageHeader,DatePicker },
  data() {
    return {
      tableData: [],
      title: "Event Sales List",
      items: [
        {
          text: "View",
        },
        {
          text: "Event Sales List",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",
          sortable: false,
        },
        {
          key: "seller_name",
          label: "Seller Name",
          sortable: true,
        },
        {
          key: "buyer_name",
          label: "Corporate Branch",
          sortable: true,
        },
        {
          key: "vendor_name",
          label: "Restaurant Branch",
          sortable: true,
        },
        {
          key: "invoice_no",
          label: "Invoice No",
          sortable: true,
        },
        {
          key: "sale_for",
          label: "Type Of Sale",
          sortable: true,
        },
        {
          key: "total_amount",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "service_date",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "created_by",
          sortable: true,
        },
        {
          key: "created_at",
          sortable: true,
        },
      ],
      path: "",
      editRight: "",
      deleteRight: "",
      countryArr: [],
      country: "",
      cityArr: [],
      city: "",
      corporateArr: [],
      corporateID: "",
      branches: [],
      cafeteriaArr: [],
      restBranch: "",
      dateFilter: [],
      isTableBusy: false,
      isLoading: 0,
      corpBranchID:"",
    };
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.totalRows = this.items.length;
    this.getAllCountry();
  },
  methods: {
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios
          .post(this.$loggedRole+"/getAllCountry", {
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type
          })
          .then(response => {
            this.countryArr = response.data.data;
            // this.country = (this.countryArr) ? this.countryArr[0] : "";
            // this.getAllCities();
          });
    },
    getAllCities() {
      this.cityArr = [];
      this.city = "";
      this.axios
          .post(this.$loggedRole+"/getAllCities", {
            country: this.country ? this.country.country : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type
          })
          .then(response => {
            this.cityArr = response.data.data;
          });
    },
    getCorporateList() {
      this.corporateArr = [];
      this.corporateID = "";
      this.axios
          .post(this.$loggedRole+"/crm/event-sales/corporate-list", {
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
            country: this.country ? this.country.country : "",
            city: this.city ? this.city.city : ""
          })
          .then(response => {
            this.corporateArr = response.data.data;
          });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getCorporateCafeteria() {
      this.axios
          .post(this.$loggedRole+"/crm/event-sales/restaurant-branch-list", {
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
            city: this.city ? this.city.city : "",
            country: this.country ? this.country.country : ""
          })
          .then(result => {
            this.cafeteriaArr = result.data.data;
          });
    },
    downloadSample() {
      this.axios
          .post(this.$loggedRole+"/exportExcel",
              {
                exportData: this.tableData,
                page: "EventSales",
              },
              {
                responseType: "blob",
              }
          )
          .then((response) => {
            const url = URL.createObjectURL(
                new Blob([response.data], {
                  type: "application/vnd.ms-excel",
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "event-sales.xlsx");
            document.body.appendChild(link);
            link.click();
          });
    },
    getCorporateBranchByCorpID() {
      var corporateID =
          this.corporateID != ""
              ? this.corporateID.corporateID
              : this.$storageData.profile.corporateID;
      this.axios
          .post(this.$loggedRole+"/crm/event-sales/corporate-branch-list", {
            corporateID: corporateID,
            city: this.city ? this.city.city : "",
            country: this.country ? this.country.country : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then(response => {
            this.branches = response.data.data;
          });
    },
    clearFilter() {
      this.corporateID = 0;
      this.restBranchArr = [];
      this.city = "";
      this.country = "";
      this.corpBranchID = 0;
      this.restBranch = 0;
      this.branchID = 0;
      this.dateFilter = [];
    },
    applyFilter() {
      if (this.dateFilter.includes(null)) {
        this.dateFilter = [];
      }
      this.getEventSalesData();
    },
    getEventSalesData() {
      this.isTableBusy = true;
      this.isLoading = 1;
      var corpBranchID = this.corpBranchID != "" && this.corpBranchID != null
              ? this.corpBranchID.corpBranchID
              : 0;
      this.axios
          .post(this.$loggedRole+"/event-sales-list",{
            corpBranchID: corpBranchID,
            dateFilter: this.dateFilter,
            restBranchID:this.restBranch != "" && this.restBranch != null
                    ? this.restBranch.restBranchID
                    : this.$storageData.profile.restBranchID,
            loginTypeID: this.$storageData.login_type,
            country: this.country ? this.country.country : "",
            city: this.city ? this.city.city : "",
          })
          .then((response) => {
            this.isTableBusy = false;
            this.isLoading = 0;
            this.tableData = response.data.data;
          })
          .catch((error)=>{
        this.isTableBusy = false;
        this.isLoading = 0;
        this.$swal({
          icon:"error",
          text:error.response.data.message,
        });
      });
    },
  },
  middleware: "authentication",
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="inner mb-2">
              <div class="row mt-3">
            <!-- For super Admins -->
            <div
                class="col-md-2"
                style="width: 12%"
            >
              <label class="form-label" style="font-size: 12px"
              >Select Country
              </label>
              <multiselect
                  v-model="country"
                  :options="countryArr"
                  :show-labels="false"
                  label="country"
                  track-by="country"
                  @input="getAllCities()"
              ></multiselect>
            </div>
            <div
                class="col-md-2"
                style="width: 12%"
            >
              <label style="font-size: 12px">Select City </label>
              <multiselect
                  v-model="city"
                  :options="cityArr"
                  :show-labels="false"
                  label="city"
                  track-by="city"
                  @input="getCorporateList(),getCorporateCafeteria()"
              ></multiselect>
            </div>
            <div
            class="col-md-2"
                style="width: 20%"
            >
              <label class="form-label" style="font-size: 12px"
              >Select Corporate
              </label>
              <multiselect
                  v-model="corporateID"
                  :options="corporateArr"
                  :show-labels="false"
                  label="corporateName"
                  track-by="corporateID"
                  @input="getCorporateBranchByCorpID()"
              ></multiselect>
            </div>

            <div
               class="col-md-2"
                style="width: 20%"
            >
              <label class="form-label" style="font-size: 12px"
              >Select Corporate Branch
              </label>
              <multiselect
                  v-model="corpBranchID"
                  :options="branches"
                  :show-labels="false"
                  label="corpBranchName"
                  track-by="corpBranchID"
              ></multiselect>
              <!-- @input="onchangeBranch();" -->
            </div>
                <div class="col-md-4">
                  <label
                      class="form-label"
                      for="formrow-endTime-input"
                      style="font-size: 12px"
                  >Select Restaurant Branch</label
                  >
                  <multiselect
                      v-model="restBranch"
                      :options="cafeteriaArr"
                      :show-labels="false"
                      label="restaurantName"
                      track-by="restBranchID"
                  ></multiselect>
                </div>
                <div class="col-sm-2 col-md-3">
                  <label
                      class="form-label"
                      for="formrow-date-input"
                      style="font-size: 12px"
                  >Date Filter</label
                  >
                  <date-picker
                      v-model="dateFilter"
                      append-to-body
                      format="DD MMM Y"
                      lang="en"
                      range
                      value-type="format"
                  ></date-picker>
                </div>
                <!-- Apply filter -->
                <div class="col-md-2" style="width: auto; margin-top: 21px">
                  <button class="btn btn-themeOrange" v-on:click="applyFilter()">
                    Apply Filter
                  </button>
                </div>
                <!-- Apply filter END-->

                <!-- Clear filter -->
                <div class="col-md-2" style="width: 16%; margin-top: 21px">
                  <button class="btn btn-themeBrown" v-on:click="clearFilter()">
                    Clear Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div v-if="isLoading == 1" class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading Please wait...</strong>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom: 15px;" v-if="this.$storageData.login_type == 1">
                <div role="group" class="btn-group">
                  <button
                      type="button"
                      class="btn btn-themeBrown"
                      @click="downloadSample()"
                  >
                    Excel
                  </button>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                        style="margin-left: 5px; margin-right: 5px"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                  :busy="isTableBusy"
                  striped
                  hover
                  outlined
                  bordered
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  :headers="headers"
                  fixed-header
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <!-- <template #cell(Action)>
                  <i class="uil uil-edit-alt" title="Edit"  style="font-size: 19px;"></i>

                  <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i>
                </template> -->

                <template v-slot:cell(Action)="data">
<!--                  <router-link-->
<!--                      :to="{-->
<!--                      name: 'addRestaurant',-->
<!--                      params: { id: data.item.restID, type: 'edit' },-->
<!--                    }"-->
<!--                      v-if="$can('edit restaurant brand')"-->
<!--                  >-->
<!--                    <i-->
<!--                        class="uil uil-edit-alt"-->
<!--                        title="Edit"-->
<!--                        style="font-size: 19px"-->
<!--                    ></i-->
<!--                    ></router-link>-->
                  <router-link
                      v-if="$can('view event sale detail')"
                      :to="{
                      name: 'EventSaleDetail',
                      params: { id: data.item.id, type: 'view' },
                    }"
                  >
                    <i
                        class="uil-info-circle"
                        title="View"
                        style="font-size: 19px; margin-left: 10px"
                    ></i
                    ></router-link>
                </template>

                <template v-slot:cell(pricePerHead)="data">
                  <span v-if="data.item.pricePerHead > 0"
                  >₹{{ data.item.pricePerHead }}</span
                  >
                  <span v-else> 0 </span>
                </template>

                <!-- <template slot="image" slot-scope="data">
                   <img  v-bind:src="data.item.name" />
                </template> -->
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>

</style>